// NovelTab.tsx
import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { Input, Select, Tooltip, Button, Space, Modal, Spin, Checkbox, Tag, Row, Col, List, Dropdown, Menu, message, Tabs} from 'antd';
import { InfoCircleOutlined, MoreOutlined, CloseOutlined, CopyOutlined  } from '@ant-design/icons'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { getFirestore, doc, updateDoc, Timestamp, getDoc } from "firebase/firestore";
import './GeneratingGifStyle.css';
import { SharedState, UsersSharedState, TabProps, CustomAxiosError, MainCharacter, Location, SupportingCharacter, Character } from './types';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

const { TextArea } = Input;
const { TabPane } = Tabs;

const StyledList = styled(List<ChapterItem>)`
  width: 95%;
  
  .ant-list-item {
    cursor: pointer;
  }
  
  .ant-list-item:hover {
    background-color: #f0f0f0;
  }
  
  .ant-list-item-selected {
    background-color: #e6f7ff;
  }
`;

interface ChapterItem {
  id: string;
  chapter_text: string;
  chapterNumber: number;
  continuity_details: string;
  outline_text: string;
}

interface OutlineItem {
  id: string;
  outline_text: string;
  chapterNumber: number;
}

const NovelTab: React.FC<TabProps> = ({ sharedState, setSharedState, isAuth, user, usersSharedState, setUsersSharedState }) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmNovelModalVisible, setIsConfirmNovelModalVisible] = useState(false);
  const [shouldSkipNovelConfirm, setShouldSkipNovelConfirm] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState<number | null>(null);
  const [currentChapterTitle, setCurrentChapterTitle] = useState('');

  const [apiModel, setApiModel] = useState("Claude")
  const [costState, setCostState] = useState<Record<string, any>>({});
  const [bookNumChapters, setBookNumChapters] = useState(6)
  const [bookPOV, setBookPOV] = useState("First Person")

  const [chapterItems, setChapterItems] = useState<ChapterItem[]>([]);
  const [selectedChapterItem, setSelectedChapterItem] = useState<ChapterItem | null>(null);
  const sortedChapterItems = [...chapterItems].sort((a, b) => a.chapterNumber - b.chapterNumber);
  const [outlineItems, setOutlineItems] = useState<OutlineItem[]>([]);
const [currentGeneratingChapter, setCurrentGeneratingChapter] = useState<number | null>(null);
const [totalChaptersToGenerate, setTotalChaptersToGenerate] = useState<number | null>(null);

  const [locations, setLocations] = useState<Location[]>([]);
  const [mainCharacters, setMainCharacters] = useState<MainCharacter[]>([]);
  const [supportingCharacters, setSupportingCharacters] = useState<SupportingCharacter[]>([]);
  const allCharacters = [...mainCharacters, ...supportingCharacters];
  const [bookTense, setBookTense] = useState("Past Tense")
  const [bookGenre, setBookGenre] = useState("")
  const [bookWritingStyle, setBookWritingStyle] = useState("")
  const [bookTropes, setBookTropes] = useState("")
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] = useState(false);
  const [chapterToDelete, setChapterToDelete] = useState<ChapterItem | null>(null);
  const isLastChapter = (chapter: ChapterItem): boolean => {
    return chapter.chapterNumber === Math.max(...chapterItems.map(item => item.chapterNumber));
  };

  const db = getFirestore();

  const novelTotalCost = Math.ceil(costState.genNovelCost * bookNumChapters);

  // Load Book data
  useEffect(() => {
    const fetchData = async () => {
      if (!sharedState.bookguid) return;
      console.log("Fetching book data (location)")
      setIsLoading(true);
      try {
        const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/api/books/${sharedState.bookguid}`);
        const data = response.data;

        let parsedLocations = [];
        try {
          parsedLocations = JSON.parse(data.locations || '[]');
          if (parsedLocations && parsedLocations.locations) {
            setLocations(parsedLocations.locations);
          } else {
            setLocations([]);
          }
        } catch (error) {
          console.error("Failed to parse locations data: ", error);
          setLocations([]);
        }

        setApiModel(data.model);
        setBookNumChapters(data.numChapters);
        setBookPOV(data.pov);

        if (data.mainCharacters) {
          setMainCharacters(data.mainCharacters);
        }
        if (data.supportingCharacters) {
          setSupportingCharacters(data.supportingCharacters);
        }

        setBookTense(data.tense);
        setBookGenre(data.genre);
        setBookWritingStyle(data.writingStyle);
        setBookTropes(data.tropes);

      } catch (error: any) {
        console.error("Failed to fetch book details: ", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sharedState.bookguid]);

  useEffect(() => {
    const fetchGenerationCosts = async () => {
      setIsLoading(true);
      try {
        const generationCostsResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get_generation_cost/');
        const costsData: Record<string, any> = generationCostsResponse.data.creditCosts;
        const generationTypes = [
          'BasicPlot', 'Tropes', 'WritingStyle', 'MainCharacters',
          'SupportingCharacters', 'Locations', 'Novel',
          'Title', 'Blurb', 'Novel', 'ChapterSummary', 'CharacterName', 'CharacterDetails',
          'LocationName', 'LocationDetails'
        ];

        let newState: Record<string, any> = {};
        for (let genType of generationTypes) {
          const key = `${genType}_${apiModel}`;
          newState[`gen${genType}Cost`] = costsData[key];
        }

        setCostState(newState);

      } catch (error) {
        console.error("Failed to fetch generation costs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (apiModel) {
      fetchGenerationCosts();
    }
  }, [sharedState.bookguid, apiModel]);

  useEffect(() => {
    setChapterItems([]);
	setOutlineItems([]);
	setSelectedChapter(null);
	setSelectedChapterItem(null);
	if (sharedState.bookguid) {
      fetchNovelData();
    }
  }, [sharedState.bookguid]);

  const fetchNovelData = async () => {
    try {
      const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/api/books/${sharedState.bookguid}`);
      const data = response.data;
      if (data.novel) {
        setChapterItems(data.novel);
      }
      if (data.outline) {
        setOutlineItems(data.outline);
      }	  
    } catch (error) {
      console.error("Failed to fetch novel data: ", error);
    }
  };

  const handleChapterItemSelect = (item: ChapterItem) => {
    setSelectedChapterItem(item);
  };

  const handleChapterTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (selectedChapterItem) {
      setSelectedChapterItem({
        ...selectedChapterItem,
        chapter_text: e.target.value
      });
    }
  };
const handleSaveChapter = async () => {
  setIsLoading(true);
  if (selectedChapterItem && sharedState.bookguid && usersSharedState.userID) {
    try {
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/api/save_chapter/', {
        bookguid: sharedState.bookguid,
        userid: usersSharedState.userID,
        chapter_id: selectedChapterItem.id,
        chapter_text: selectedChapterItem.chapter_text
      });

      if (response.status === 200) {
        setChapterItems(prevItems =>
          prevItems.map(item =>
            item.id === selectedChapterItem.id ? selectedChapterItem : item
          )
        );
		
		await fetchNovelData();
        message.success('Chapter saved successfully');
      } else {
        throw new Error('Failed to save chapter');
      }
    } catch (error) {
      console.error("Error saving chapter: ", error);
      message.error('Failed to save chapter');
    } finally {
      setIsLoading(false);
    }
  }
};

const handleDeleteChapter = (chapter: ChapterItem) => {
  console.log("handleDeleteChapter");
  if (isLastChapter(chapter)) {
    setChapterToDelete(chapter);
    setIsDeleteConfirmModalVisible(true);
  }
  else {
	console.log("not last chapter");  
  }
};

  const confirmDeleteChapter = async () => {
    if (chapterToDelete && sharedState.bookguid && usersSharedState.userID) {
      setIsLoading(true);
      try {
        const response = await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/api/delete_create_book_chapter/?bookguid=${sharedState.bookguid}&userid=${usersSharedState.userID}&chapter_id=${chapterToDelete.id}`);


        if (response.status === 200) {
          setChapterItems(prevItems => prevItems.filter(item => item.id !== chapterToDelete.id));
          setSelectedChapterItem(null);
          message.success('Chapter deleted successfully');
        } else {
          throw new Error('Failed to delete chapter');
        }
      } catch (error) {
        console.error("Error deleting chapter: ", error);
        message.error('Failed to delete chapter');
      } finally {
        setIsLoading(false);
        setIsDeleteConfirmModalVisible(false);
        setChapterToDelete(null);
      }
    }
  };

const handleCopyEntireNovel = () => {
  const sortedChapters = [...chapterItems].sort((a, b) => a.chapterNumber - b.chapterNumber);
  
  const novelHtmlText = sortedChapters
    .map(item => {
      const chapterText = item.chapter_text.replace(
        /(Chapter \d+)/g,
        '<h1>$1</h1>'
      );
      return `${chapterText}\n\n`;
    })
    .join('');

  copyHtmlToClipboard(novelHtmlText);
};

function copyHtmlToClipboard(htmlContent: string) {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.left = '-9999px';
  tempDiv.style.top = '-9999px';
  tempDiv.style.opacity = '0';
  tempDiv.contentEditable = 'true';
  
  // Replace newlines with <br> tags
  const contentWithLineBreaks = htmlContent.replace(/\n/g, '<br>');
  tempDiv.innerHTML = contentWithLineBreaks;
  
  document.body.appendChild(tempDiv);

  const range = document.createRange();
  range.selectNodeContents(tempDiv);
  const selection = window.getSelection();
  
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      message.success('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      message.error('Failed to copy content');
    } finally {
      selection.removeAllRanges();
      document.body.removeChild(tempDiv);
    }
  } else {
    console.error('Unable to get selection');
    message.error('Failed to copy content');
    document.body.removeChild(tempDiv);
  }
}


const handleCopyContent = (tabKey: string) => {
  if (!selectedChapterItem) return;

  let contentToCopy = '';
  switch (tabKey) {
    case '1':
      contentToCopy = selectedChapterItem.chapter_text;
      break;
    case '2':
      contentToCopy = selectedChapterItem.continuity_details;
      break;
    case '3':
      contentToCopy = outlineItems.find(item => item.chapterNumber === selectedChapterItem.chapterNumber)?.outline_text || '';
      break;
  }

  copyHtmlToClipboard(contentToCopy);
};


  const handleInfoModal = (visible: boolean, source: string) => {
    if (source === 'Novel') {
      setInfoTitle("Novel Information");
      setInfoContent(`ScribeShadow can generate your novel chapter by chapter.

      ScribeShadow will use the Genre, Tropes, Plot, Main Characters, Supporting Characters, Locations, and Outline provided so far when creating your novel.

      The length of your novel will depend on the number of chapters you specified in the High Level tab.

      You can edit and save each chapter individually after generation.`);
    }
    setIsInfoModalVisible(visible);
  };

  let infoModalContent = (
    <p>
      {infoContent.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );

  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };


const findNextChapterNumber = (): number => {
  if (chapterItems.length === 0) return 1;
  const maxChapterNumber = Math.max(...chapterItems.map(item => item.chapterNumber));
  return maxChapterNumber + 1;
};

const handleGenerateNovelClick = async () => {
  const nextChapterNumber = findNextChapterNumber();
  if (nextChapterNumber > bookNumChapters) {
    message.info('All chapters have been generated.');
    return;
  }
  setTotalChaptersToGenerate(bookNumChapters);
  setCurrentGeneratingChapter(nextChapterNumber);
  await handleGenerateNovel(nextChapterNumber);
  setCurrentGeneratingChapter(null);
  setTotalChaptersToGenerate(null);
};


const handleGenerateNovel = async (chapterNumber: number, skipLoadingState = false) => {
  if (!skipLoadingState) {
    setIsLoading(true);
    setCurrentGeneratingChapter(chapterNumber);
    setTotalChaptersToGenerate(bookNumChapters);
  }

  try {
    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate_novel/', {
      userID: usersSharedState.userID,
      bookguid: sharedState.bookguid,
      chapterNumber: chapterNumber
    });

    await fetchNovelData();
    message.success(`Chapter ${chapterNumber} generated successfully`);
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage(`An error occurred while generating the Novel.\n ${err}`);
    }
    handleErrorModal(true);
    throw err; // Add this line to re-throw the error
  } finally {
    if (!skipLoadingState) {
      setIsLoading(false);
      setCurrentGeneratingChapter(null);
      setTotalChaptersToGenerate(null);
    }
  }
};


const handleGenerateReminingNovel = async () => {
  let nextChapterNumber = findNextChapterNumber();
  const chaptersRemaining = bookNumChapters - (nextChapterNumber - 1);

  if (chaptersRemaining <= 0) {
    message.info('All chapters have been generated.');
    return;
  }

  setTotalChaptersToGenerate(bookNumChapters);
  setIsLoading(true);

  for (let chapterNumber = nextChapterNumber; chapterNumber <= bookNumChapters; chapterNumber++) {
    setCurrentGeneratingChapter(chapterNumber);
    try {
      await handleGenerateNovel(chapterNumber, true);
    } catch (error) {
      message.error(`Error generating chapter ${chapterNumber}`);
      break;
    }
  }

  setIsLoading(false);
  setCurrentGeneratingChapter(null);
  setTotalChaptersToGenerate(null);
  message.success('Novel generation completed');
};



const handleRegenerateChapter = async (chapterNumber: number) => {
  setIsLoading(true);
  try {
    const result = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/regenerate_novel_chapter/', {
      userID: usersSharedState.userID,
      chapterNumber: chapterNumber,
      bookguid: sharedState.bookguid
    });


    // Refresh the chapter list
    await fetchNovelData();

    message.success('Chapter regenerated successfully');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(`An error occurred while regenerating the Chapter.\n ${error.response?.data.detail}`);
    } else {
      setErrorMessage(`An error occurred while regenerating the Chapter.\n ${error}`);
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};

const getWordCount = (text: string): number => {
  return text.trim().split(/\s+/).length;
};

  return (
    <Spin spinning={isLoading} tip="Saving...">
      <div>
        {!sharedState.bookguid && isAuth && (
          <Tooltip title="Please start or open a book in the Book Management tab.">
            <p><b>Please start or open a book in the Book Management tab.<br />Fields will become available once a book is in progress.</b></p>
          </Tooltip>
        )}
        {!isAuth && (
          <Tooltip title="Please click Get Started.">
            <p><b>Click Get Started to sign up and start generating books!</b></p>
          </Tooltip>
        )}
        <Row gutter={16}>
          <Col span={5.5}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ marginRight: '5px', fontSize: '16px' }}><b>Chapters</b></p>
                <Tooltip title="Click for more information">
                  <InfoCircleOutlined
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={() => handleInfoModal(true, 'Novel')}
                  />
                </Tooltip>
              </div>
              <div style={{ height: '745px', overflow: 'auto' }}>
<StyledList
  dataSource={sortedChapterItems}
  renderItem={(item: ChapterItem) => (
    <List.Item
      key={item.id}
      style={{
        cursor: 'pointer',
        backgroundColor: selectedChapterItem?.id === item.id ? '#e6f7ff' : undefined,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onClick={() => handleChapterItemSelect(item)}
    >
      <span>Chapter {item.chapterNumber}</span>
<Dropdown
  overlay={
    <Menu
      onClick={(info) => {
        if (info.key === '1') {
          handleRegenerateChapter(item.chapterNumber);
        }
      }}
    >
      <Menu.Item key="1">
        Regenerate Chapter
      </Menu.Item>
    </Menu>
  }
  trigger={['click']}
>
  <MoreOutlined
    style={{ fontSize: '16px' }}
    onClick={(e) => e.stopPropagation()}
  />
</Dropdown>
    </List.Item>
  )}
/>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
<Button
  type="primary"
  onClick={handleGenerateNovelClick}
  disabled={findNextChapterNumber() > bookNumChapters || isLoading}
  style={{ marginTop: '10px' }}
>
  Generate Next Chapter
</Button>

<Button
  type="primary"
  onClick={handleGenerateReminingNovel}
  disabled={findNextChapterNumber() > bookNumChapters || isLoading}
  style={{ marginTop: '10px' }}
>
  Generate Novel
</Button>
                <Button
                  onClick={handleCopyEntireNovel}
                  disabled={chapterItems.length === 0}
                  style={{ marginTop: '10px' }}
                >
                  Copy Entire Novel
                </Button>
              </div>
            </div>
          </Col>
          <Col span={20}>
            <div>
              <p style={{ marginRight: '5px', fontSize: '16px' }}><b>Chapter Content</b></p>
              {selectedChapterItem ? (
<div style={{ display: 'flex', alignItems: 'center' }}>
  <Tabs 
    defaultActiveKey="1" 
    style={{ flex: 1 }}
    onChange={(key) => setActiveTabKey(key)}
    tabBarExtraContent={
      <Tooltip title={`Copy ${activeTabKey === '1' ? 'Chapter' : activeTabKey === '2' ? 'Continuity Details' : 'Outline'}`}>
        <CopyOutlined 
          style={{ fontSize: '20px', cursor: 'pointer' }}
          onClick={() => handleCopyContent(activeTabKey)}
        />
      </Tooltip>
    }
  >
<TabPane tab="Chapter Content" key="1">
  <div style={{ position: 'relative', marginBottom: '10px' }}>
    <div style={{ position: 'absolute', top: '-25px', left: '0', zIndex: 1 }}>
      <Tag color="blue">{getWordCount(selectedChapterItem.chapter_text)} words</Tag>
    </div>
  </div>
  <TextArea
    value={selectedChapterItem.chapter_text}
    onChange={handleChapterTextChange}
    autoSize={{ minRows: 10, maxRows: 34 }}
  />
  <Space style={{ marginTop: '10px' }}>
    <Button onClick={handleSaveChapter}>Save Chapter</Button>
    <Tooltip title={!isLastChapter(selectedChapterItem) ? "Only the last chapter can be deleted" : ""}>
      <Button 
        danger 
        onClick={() => handleDeleteChapter(selectedChapterItem)}
        disabled={!isLastChapter(selectedChapterItem)}
      >
        Delete Chapter
      </Button>
    </Tooltip>
  </Space>
</TabPane>
                  <TabPane tab="Continuity Details" key="2">
                    <TextArea
                      value={selectedChapterItem.continuity_details}
                      readOnly
                      autoSize={{ minRows: 10, maxRows: 20 }}
                    />
                  </TabPane>
<TabPane tab="Outline" key="3">
  <TextArea
    value={outlineItems.find(item => item.chapterNumber === selectedChapterItem.chapterNumber)?.outline_text || ''}
    readOnly
    autoSize={{ minRows: 10, maxRows: 20 }}
  />
</TabPane>				  
                </Tabs>
				</div>
              ) : (
                <p style={{ textAlign: "center" }}>Select a chapter to view and edit its content</p>
              )}
            </div>
          </Col>
        </Row>

        {/* Modals */}
        <Modal
          title={infoTitle}
          visible={isInfoModalVisible}
          onOk={() => handleInfoModal(false, "")}
          onCancel={() => handleInfoModal(false, "")}
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button key="submit" type="primary" onClick={() => handleInfoModal(false, "")}>
                OK
              </Button>
            </div>
          }
        >
          {infoModalContent}
        </Modal>

        <Modal
          title="Error"
          visible={isErrorModalVisible}
          onOk={() => setErrorModalVisible(false)}
          onCancel={() => setErrorModalVisible(false)}
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
                OK
              </Button>
            </div>
          }
        >
          <p>{errorMessage}</p>
        </Modal>

<Modal
  title={null}
  visible={isLoading}
  footer={null}
  centered
  closable={false}
  maskClosable={false}
  style={{
    textAlign: 'center',
    background: 'transparent',
    boxShadow: 'none',
    width: 'auto'
  }}
>
  <div>
    <img src="generating.gif" alt="Generating..." className="responsive-gif" />
    {currentGeneratingChapter && totalChaptersToGenerate && (
      <p>Generating Chapter {currentGeneratingChapter} of {bookNumChapters}</p>
    )}
  </div>
</Modal>


<Modal
  title="Confirm Deletion"
  visible={isDeleteConfirmModalVisible}
  onOk={confirmDeleteChapter}
  onCancel={() => setIsDeleteConfirmModalVisible(false)}
>
  <p>Are you sure you want to delete this chapter?</p>
</Modal>



      </div>
    </Spin>
  );
};

export default NovelTab;