//Pricing.tsx
import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Row, Col, Grid, Divider } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { CSSProperties } from 'react';

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

type PlanType = {
  name: string;
  monthlyPrice: string;
  yearlyPrice: string;
  credits: string;
  description: string;
  isPopular?: boolean;
};

interface PricingProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;  
  showLoginModal: () => void;
}

const Pricing: React.FC<PricingProps> = ({ isAuth, usersSharedState, setUsersSharedState, showLoginModal }) => {
  const [isYearly, setIsYearly] = useState(false);
  const screens = useBreakpoint();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const plans: PlanType[] = [
    {
      name: 'Inkling',
      monthlyPrice: '4.99',
      yearlyPrice: '49.99',
      credits: '10,000',
      description: 'Ideal for new writers, this plan offers enough credits for short stories or sampling of AI translation.',
      isPopular: false,
    },
    {
      name: 'Storyteller',
      monthlyPrice: '24.99',
      yearlyPrice: '249.99',
      credits: '55,000',
      description: 'The Storyteller plan provides ample credits to bring your novellas to life in different languages, enhancing your storytelling reach.',
      isPopular: false,
    },
    {
      name: 'Wordsmith',
      monthlyPrice: '49.99',
      yearlyPrice: '499.99',
      credits: '120,000',
      description: 'The Wordsmith plan is suited for authors translating full-length novels monthly, perfect for authors aiming to expand their readership internationally.',
      isPopular: true,
    },
    {
      name: 'Novelist',
      monthlyPrice: '99.99',
      yearlyPrice: '999.99',
      credits: '250,000',
      description: 'For established authors, the Novelist plan offers ample credits for translating novels into multiple languages.',
      isPopular: false,
    },
    {
      name: 'Bestseller',
      monthlyPrice: '199.99',
      yearlyPrice: '1999.99',
      credits: '600,000',
      description: 'The Bestseller plan enables the translation of an extensive catalogue of works. It\'s an ideal choice for prolific authors who have a stream of content requiring translation.',
      isPopular: false,
    },
    {
      name: 'Publisher',
      monthlyPrice: '399.99',
      yearlyPrice: '0',
      credits: '1,300,000',
      description: 'The Publisher plan is perfect for publishing houses and professional writers who manage a large volume of content. This plan provides ample credits to ensure your extensive library is translated quickly.',
      isPopular: false,
    },
  ];

  const PricingCard: React.FC<{ plan: PlanType }> = ({ plan }) => {
    const commonCardStyle = {
      width: screens.md ? '375px' : '100%',
      maxWidth: '375px',
      textAlign: 'center' as const,
      margin: '10px auto',
      padding: '25px 20px 20px 20px',
      borderRadius: '10px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      border: '1px solid #eaeaea',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column' as const,
    };

    const popularCardStyle = {
      ...commonCardStyle,
      padding: '0 20px 20px 20px',
    };

    const popularHeaderStyle: CSSProperties = {
      backgroundColor: '#e6f7ff',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      position: 'relative',
      borderBottom: '1px solid #eaeaea',
    };

    const commonHeaderStyle: CSSProperties = {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      borderBottom: '1px solid #eaeaea',
    };

    const descriptionStyle: CSSProperties = {
      minHeight: '120px',
    };

    return (
      <Card
        style={plan.isPopular ? popularCardStyle : commonCardStyle}
        headStyle={plan.isPopular ? popularHeaderStyle : commonHeaderStyle}
        bodyStyle={{
          padding: '20px',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {plan.isPopular && (
          <div style={{ color: '#1890ff', zIndex: 1, textAlign: 'center' }}>
            <CheckCircleOutlined /> <strong> Most popular </strong>
          </div>
        )}
        <Title level={4}>{plan.name}</Title>
        <div style={descriptionStyle}>
          <Paragraph>{plan.description}</Paragraph>
        </div>
        <div>
          <Paragraph strong>This includes:</Paragraph>
          <Paragraph>{plan.credits} monthly translation credits</Paragraph>
          <Title level={4}>${plan.monthlyPrice}</Title>
          <Paragraph>per month</Paragraph>
          {plan.yearlyPrice !== '0' && plan.yearlyPrice !== 'N/A' ? (
            <Paragraph>${plan.yearlyPrice} when billed yearly</Paragraph>
          ) : (
            <Paragraph type="secondary">Yearly billing is not available</Paragraph>
          )}
        </div>
        <Button type="primary" onClick={showLoginModal}>Log In to Purchase</Button>
        <Text type="secondary" style={{ marginTop: '10px', fontSize: '12px' }}>
          Each translated word costs approximately one credit.
        </Text>
      </Card>
    );
  };

  return (
    <div style={{ backgroundColor: '#f0f2f5', padding: '2em', minHeight: '100vh' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <Title level={2} style={{ textAlign: 'center', marginBottom: '1em' }}>
          Pricing
        </Title>

        {isAuth ? (
          <Card title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>Upgrade Your Plan</div>} style={{ marginTop: '2em' }}>
            <stripe-pricing-table 
              pricing-table-id="prctbl_1OsHPdEj6HHcTsCPS2AB2xCg"
              publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
			  client-reference-id={usersSharedState.userID}
            />
          
        <Divider />
        <stripe-pricing-table 
          pricing-table-id="prctbl_1OsHQdEj6HHcTsCPR7B7Erfz"
          publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
          client-reference-id={usersSharedState.userID}
        />
        <Divider />
        <center><h1>Purchase Forever Credits A la Carte</h1></center>
    <stripe-pricing-table 
      pricing-table-id="prctbl_1OsHRkEj6HHcTsCPwALbwTSF"
      publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
      client-reference-id={usersSharedState.userID}
    />	</Card>	  
        ) : (
          <Row gutter={[24, 24]}>
            {plans.map(plan => (
              <Col key={plan.name} xs={24} sm={12} lg={8}>
                <PricingCard plan={plan} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

export default Pricing;