//Account.tsx
import React, { useState, useEffect  } from 'react';
import { Card, Switch, Typography, Button, Modal, Divider, Row, Col, Descriptions, Space, Grid, Tooltip, Select, message } from 'antd';
import { CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CheckoutForm } from './CheckoutForm'; 
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UsersSharedState } from './Create/types';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { Alert, Spin, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph  } = Typography;
const { useBreakpoint } = Grid;
const { Option } = Select;

type PlanType = {
  name: string;
  monthlyPrice: string;
  yearlyPrice: string;
  credits: string;
  description: string;
  isPopular?: boolean;
};

interface AccountProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;
  showLoginModal: () => void;
}


interface PlanDetails {
  planName?: string;
  monthlyCredits?: number;
  tier?: number;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const Account: React.FC<AccountProps> = ({ isAuth, usersSharedState, setUsersSharedState, showLoginModal  }) => {
  
  const [isYearly, setIsYearly] = useState(false);
  const [planDetails, setPlanDetails] = useState<PlanDetails>({});
  const [isCheckoutModalVisible, setisCheckoutModalVisible] = useState(false);
  const [isLoadingPlanDetails, setIsLoadingPlanDetails] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState('');
  const [foreverCredits, setForeverCredits] = useState(0); 
  const pageDisabled = false;
  const disabledMessage = "After a lot of feedback, we're making improvements. Check back soon! -Team ScribeShadow";
  const screens = useBreakpoint();
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
  const baseLanguages = ["Dutch", "English", "British English", "French", "German", "Italian", "European Portuguese", "Brazilian Portuguese", "Spanish"];
  
useEffect(() => {
  const fetchDetails = async () => {
    if (isAuth && usersSharedState.userID) {
      setIsLoadingPlanDetails(true);
      try {
        const creditResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/', {
          params: {
            userID: usersSharedState.userID,
          },
        });

        const planID = creditResponse.data.planID;
        const planResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/get_plan_details/${planID}`);
        const subscriptionResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/subscription-details/${usersSharedState.userID}`);
        
        setUsersSharedState({
          ...usersSharedState,
          credits: creditResponse.data.credit_balance,
          maxCharacters: subscriptionResponse.data.maxCharacters ?? 1,
          maxLocations: subscriptionResponse.data.maxLocations ?? 1,
          maxChapters: subscriptionResponse.data.maxChapters ?? 1,
          gpt4Allowed: subscriptionResponse.data.gpt4Allowed ?? false
        });
		
		setAffiliateCode(subscriptionResponse.data.affiliateCode ?? '')
		console.log("subscriptionResponse.data.affiliateCode: "+subscriptionResponse.data.affiliateCode);

        setForeverCredits(creditResponse.data.forever_credits);
		setPlanDetails({
          planName: planResponse.data.planName,
          monthlyCredits: planResponse.data.monthlyCredits,
          tier: planResponse.data.tier,
        });

        // Fetch the current default language
        const languageResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get-default-language', {
          params: { userID: usersSharedState.userID }
        });
        setDefaultLanguage(languageResponse.data.defaultLanguage);


      } catch (error) {
        console.error('Failed to fetch details:', error);
      } finally {
        setIsLoadingPlanDetails(false);
      }
    }
  };
  fetchDetails();
}, [isAuth, usersSharedState.userID, planDetails.tier]);



    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        // This function will run when the component unmounts.
        return () => {
            document.body.removeChild(script);
        }
    }, []);
  
  
  
  const handleBilling = async () => {
    try {
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/create-customer-portal-session', {
        userID: usersSharedState.userID,
      });
      window.open(response.data.url, '_blank');
    } catch (error) {
      console.error('Failed to open customer portal:', error);
    }
  };  
  
  
const plans: PlanType[] = [
  {
    name: 'Inkling',
    monthlyPrice: '4.99',
    yearlyPrice: '49.99',
    credits: '10,000',
    description: 'Ideal for new writers, this plan offers enough credits for short stories or sampling of AI translation.',
	isPopular: false,
  },
  {
    name: 'Storyteller',
    monthlyPrice: '24.99',
    yearlyPrice: '249.99',
    credits: '55,000',
    description: 'The Storyteller plan provides ample credits to bring your novellas to life in different languages, enhancing your storytelling reach.',
	isPopular: false,
  },
  {
    name: 'Wordsmith',
    monthlyPrice: '49.99',
    yearlyPrice: '499.99',
    credits: '120,000',
    description: 'The Wordsmith plan is suited for authors translating full-length novels monthly, perfect for authors aiming to expand their readership internationally.',
	isPopular: true,
  },
  {
    name: 'Novelist',
    monthlyPrice: '99.99',
    yearlyPrice: '999.99',
    credits: '250,000',
    description: 'For established authors, the Novelist plan offers ample credits for translating novels into multiple languages.',
	isPopular: false,
  },
  {
    name: 'Bestseller',
    monthlyPrice: '199.99',
    yearlyPrice: '1999.99',
    credits: '600,000',
    description: 'The Bestseller plan enables the translation of an extensive catalogue of works. It\'s an ideal choice for prolific authors who have a stream of content requiring translation.',
	isPopular: false,
  },
  {
    name: 'Publisher',
    monthlyPrice: '399.99',
	yearlyPrice: '0',
    credits: '1,300,000',
    description: 'The Publisher plan is perfect for publishing houses and professional writers who manage a large volume of content. This plan provides ample credits to ensure your extensive library is translated quickly.',
	isPopular: false,
  },
];

const handleDefaultLanguageChange = async (value: string) => {
  setIsUpdatingLanguage(true);
  try {
    await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/update-default-language', {
      userID: usersSharedState.userID,
      language: value
    });
    setDefaultLanguage(value);
    message.success('Default language updated successfully');
  } catch (error) {
    console.error('Failed to update default language:', error);
    message.error('Failed to update default language');
  } finally {
    setIsUpdatingLanguage(false);
  }
};

const PricingCard: React.FC<{ plan: PlanType }> = ({ plan }) => {

  const commonCardStyle = {
    width: screens.md ? '375px' : '100%',
	maxWidth: '375px',
    textAlign: 'center' as const,
    margin: '10px auto',
    padding: '25px 20px 20px 20px',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    border: '1px solid #eaeaea', 
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
  };


  const popularCardStyle = {
    width: screens.md ? '375px' : '100%',
	maxWidth: '375px',
    textAlign: 'center' as const,
    margin: '10px auto', 
	padding: '0 20px 20px 20px',
    borderRadius: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    border: '1px solid #eaeaea', 
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
  };

  const popularHeaderStyle: CSSProperties = {
    backgroundColor: '#e6f7ff', 
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    position: 'relative',
    borderBottom: '1px solid #eaeaea',
  };

  const commonHeaderStyle: CSSProperties = {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottom: '1px solid #eaeaea',
  };

  const descriptionStyle: CSSProperties = {
    minHeight: '120px', // Adjust the min-height as needed to match your content
  }; 

  return (
    <Card
      style={plan.isPopular ? popularCardStyle : commonCardStyle}
      headStyle={plan.isPopular ? popularHeaderStyle : commonHeaderStyle}
      bodyStyle={{
        padding: '20px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {plan.isPopular && (
        <div style={{ color: '#1890ff', zIndex: 1, textAlign: 'center' }}>
          <CheckCircleOutlined /> <strong> Most popular </strong>
        </div>
      )}
      <Title level={4}>{plan.name}</Title>
	  <Divider />
      <div style={descriptionStyle}>
        <Paragraph>{plan.description}</Paragraph>
      </div>
      <div>
        <Paragraph strong>This includes:</Paragraph>
        <Paragraph>{plan.credits} monthly translation credits</Paragraph>
        <Title level={4}>${plan.monthlyPrice}</Title>
        <Paragraph>per month</Paragraph>
        {plan.yearlyPrice !== '0' && plan.yearlyPrice !== 'N/A' ? (
          <Paragraph>${plan.yearlyPrice} when billed yearly</Paragraph>
        ) : (
          <Paragraph type="secondary">Yearly billing is not available</Paragraph>
        )}
      </div>
      <Button type="primary" onClick={showLoginModal}>Log In to Purchase</Button>
      <Text type="secondary" style={{ marginTop: '10px', fontSize: '12px' }}>
        Each translated word costs approximately one credit.
      </Text>	  
    </Card>
  );
};

return (
  <div style={{ backgroundColor: '#f0f2f5', padding: '2em', minHeight: '100vh' }}>
    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
      {pageDisabled ? (
        <Alert message={disabledMessage} type="warning" showIcon />
      ) : (
        <>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '1em' }}>
            {isAuth ? 'My Account' : 'Pricing'}
          </Title>

          {isAuth ? (
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={16}>
                <Card
                  title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>Current Plan</div>}
                  style={{ height: '100%' }}
                >
                  {isLoadingPlanDetails ? (
                    <div style={{ textAlign: 'center', padding: '2em' }}>
                      <Spin size="large" />
                      <p>Loading your plan details...</p>
                    </div>
                  ) : (
                    <>
                      <Title level={4} style={{ marginBottom: '1em', textAlign: 'center' }}>{planDetails.planName}</Title>
                      <Descriptions bordered column={1} layout="vertical">
                        <Descriptions.Item label="Total Credit Balance">{usersSharedState.credits}</Descriptions.Item>
                        <Descriptions.Item label="This Month's Remaining Credits">{usersSharedState.credits - foreverCredits}</Descriptions.Item>
                        <Descriptions.Item label="Forever Credits">{foreverCredits}</Descriptions.Item>
                        <Descriptions.Item label="Monthly Credits">{planDetails.monthlyCredits}</Descriptions.Item>
                      </Descriptions>
                      <div style={{ marginTop: '1em', textAlign: 'center' }}>
                        <Link to="/credit-history">View Credit Transaction History</Link>
                      </div>
                     {planDetails.tier !== 0 && (
                        <div style={{ marginTop: '1em', textAlign: 'center' }}>
                          <Button type="primary" onClick={handleBilling}>Manage Billing</Button>
                        </div>
                      )}					  
                    </>
                  )}
                </Card>
              </Col>
              <Col xs={24} lg={8}>

				{/* Settings Card */}
				<Card 
				title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>Settings</div>} 
				style={{ marginBottom: '23px', height: '35%' }}
				>
				<div style={{ textAlign: 'center' }}>
					<Title level={4}>
					Default Translation Language
					<Tooltip title="This language will be automatically selected when you start a new translation. You can always change it for individual translations.">
						<QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
					</Tooltip>
					</Title>
					<Spin spinning={isUpdatingLanguage}>
					<Select
						style={{ width: '100%' }}
						placeholder="Select default language"
						onChange={handleDefaultLanguageChange}
						value={defaultLanguage}
						disabled={isUpdatingLanguage}
					>
						<Option value="">None</Option>
						{baseLanguages.map((language) => (
						<Option key={language} value={language}>
							{language}
						</Option>
						))}
					</Select>
					</Spin>
				</div>
				</Card>

				{/* Referral Card */}
                <Card title={<div style={{ textAlign: 'center', fontSize: '1.5em' }}>Referral Program</div>} style={{ height: '61.75%' }}>
                  {affiliateCode ? (
                    <div style={{ textAlign: 'center' }}>
                      <Title level={4}>Your Referral Code:</Title>
                      <h2>{affiliateCode}</h2>
                      <Text type="secondary">
                        Share this code with new users to give them a discount and earn free credits!
                      </Text>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Title level={4}>Want to be an affiliate?</Title>
                      <Text>
                        Email <a href="mailto:contact@scribeshadow.com">contact@scribeshadow.com</a> to get an affiliate code that offers a discount to new users and earns you free credits!
                      </Text>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          ) : (
            <Row gutter={[24, 24]}>
              {plans.map(plan => (
                <Col key={plan.name} xs={24} sm={12} lg={8}>
                  <PricingCard plan={plan} />
                </Col>
              ))}
            </Row>
          )}

{isAuth && (
  <Card title={
    <div style={{ textAlign: 'center', fontSize: '1.5em' }}>
      {planDetails.tier === 0 ? 'Upgrade Your Plan' : 'Purchase Forever Credits A la Carte'}
    </div>
  } style={{ marginTop: '2em' }}>
    {planDetails.tier === 0 ? (
      <>
        <stripe-pricing-table 
          pricing-table-id="prctbl_1OsHPdEj6HHcTsCPS2AB2xCg"
          publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
          client-reference-id={usersSharedState.userID}
        />
        <Divider />
        <stripe-pricing-table 
          pricing-table-id="prctbl_1OsHQdEj6HHcTsCPR7B7Erfz"
          publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
          client-reference-id={usersSharedState.userID}
        />
        <Divider />
        <center><h1>Purchase Forever Credits A la Carte</h1></center>
      </>
    ) : null}
    <stripe-pricing-table 
      pricing-table-id="prctbl_1OsHRkEj6HHcTsCPwALbwTSF"
      publishable-key="pk_live_51NRFOxEj6HHcTsCPvhzzF60Cq1CF8cLb4H5u325kVom6Mo3s5vYG773YjxvDDAm5LQvwYDZHm3y8QWtVAMHCYJJS00nnNHDf1L"
      client-reference-id={usersSharedState.userID}
    />
  </Card>
)}
        </>
      )}
    </div>
  </div>
);
};

export default Account;
