import React, { useState, useEffect } from 'react';
import { Card, Table, Typography, Spin, DatePicker, Select, Input, Statistic  } from 'antd';
import { UsersSharedState } from './Create/types';
import axios from 'axios';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface CreditHistoryProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;
  showLoginModal: () => void;
}

interface Transaction {
  book: string;
  book_guid: string;
  chapter: string;
  chapter_guid: string;
  credits: number;
  credits_after: number;
  credits_before: number;
  description: string;
  language: string;
  timestamp: string;
  type: 'deduction' | 'addition';
}

const CreditHistory: React.FC<CreditHistoryProps> = ({ usersSharedState }) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  const [bookFilter, setBookFilter] = useState<string | null>(null);
  const [descriptionFilter, setDescriptionFilter] = useState<string | null>(null);
  const [languageFilter, setLanguageFilter] = useState<string | null>(null);
  const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
  const [pageSize, setPageSize] = useState(10);

const fetchTransactions = async () => {
  if (usersSharedState.userID) {
    setLoading(true);
    try {
      const response = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/credit-history', {
        params: {
          userID: usersSharedState.userID,
          start_date: dateRange ? dateRange[0].toISOString() : undefined,
          end_date: dateRange ? dateRange[1].toISOString() : undefined,
        },
      });
      setTransactions(response.data);
    } catch (error) {
      console.error('Failed to fetch credit history:', error);
    } finally {
      setLoading(false);
    }
  }
};

  useEffect(() => {
    const filtered = transactions.filter(transaction => 
      (!bookFilter || transaction.book === bookFilter) &&
      (!languageFilter || transaction.language === languageFilter) &&
      (!descriptionFilter || transaction.description.toLowerCase().includes(descriptionFilter.toLowerCase()))
    );
    setFilteredTransactions(filtered);
  }, [transactions, bookFilter, languageFilter, descriptionFilter]);

  useEffect(() => {
    fetchTransactions();
  }, [usersSharedState.userID, dateRange]);

  const getUniqueValues = (key: keyof Transaction) => {
    if (bookFilter) {
      return Array.from(new Set(transactions.filter(t => t.book === bookFilter).map(t => t[key])));
    }
    return Array.from(new Set(transactions.map(t => t[key])));
  };

  const uniqueBooks = getUniqueValues('book');
  const uniqueLanguages = getUniqueValues('language');
  const uniqueDescriptions = getUniqueValues('description');

  const columns = [
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (text: string) => new Date(text).toLocaleString(),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Book',
      dataIndex: 'book',
      key: 'book',
    },
    {
      title: 'Chapter',
      dataIndex: 'chapter',
      key: 'chapter',
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
    },
    {
      title: 'Credits',
      dataIndex: 'credits',
      key: 'credits',
      render: (text: number, record: Transaction) => (
        <span style={{ color: record.type === 'deduction' ? 'red' : 'green' }}>
          {record.type === 'deduction' ? '-' : '+'}
          {text}
        </span>
      ),
    },
  ];

  const totalCredits = filteredTransactions.reduce((total, transaction) => {
    return transaction.type === 'deduction' ? total - transaction.credits : total + transaction.credits;
  }, 0);

  return (
    <div style={{ backgroundColor: '#f0f2f5', padding: '2em', minHeight: '100vh' }}>
      <div style={{ maxWidth: '1400px', margin: '0 auto' }}>
        <Title level={2} style={{ textAlign: 'center', marginBottom: '1em' }}>
          Credit Usage History
        </Title>
        <Card>
          <div style={{ marginBottom: '1em' }}>
            <RangePicker
              onChange={(dates) => {
                if (dates) {
                  setDateRange([new Date(dates[0] as any), new Date(dates[1] as any)]);
                } else {
                  setDateRange(null);
                }
              }}
              style={{ marginRight: '1em' }}
            />
            <Select
              style={{ width: 300, marginRight: '1em' }}
              placeholder="Filter by Book"
              allowClear
              onChange={setBookFilter}
            >
              {uniqueBooks.map(book => (
                <Option key={book} value={book}>{book}</Option>
              ))}
            </Select>
            <Select
              style={{ width: 300, marginRight: '1em' }}
              placeholder="Filter by Language"
              allowClear
              onChange={setLanguageFilter}
            >
              {uniqueLanguages.map(lang => (
                <Option key={lang} value={lang}>{lang}</Option>
              ))}
            </Select>
            <Select
              style={{ width: 250 }}
              placeholder="Filter by Description"
              allowClear
              onChange={setDescriptionFilter}
            >
              {uniqueDescriptions.map(desc => (
                <Option key={desc} value={desc}>{desc}</Option>
              ))}
            </Select>			
          </div>
          {loading ? (
            <div style={{ textAlign: 'center', padding: '2em' }}>
              <Spin size="large" />
              <p>Loading your credit history...</p>
            </div>
          ) : (
            <>
              <Table
                dataSource={filteredTransactions}
                columns={columns}
                rowKey={(record) => record.timestamp}
                pagination={{
                  pageSize: pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  onShowSizeChange: (current, size) => setPageSize(size),
                }}
              />

<Statistic
  title="Total Credits Used"
  value={Math.abs(totalCredits)}
  style={{ textAlign: 'right', marginBottom: '1em' }}
/>		

            </>
			
          )}
        </Card>
      </div>
    </div>
  );
};

export default CreditHistory;