// OutlineTab.tsx
import React, { ChangeEvent, useState,useEffect, useCallback  } from 'react';
import { Input, Select, Tooltip, Button, Space, Modal, Spin, Checkbox, Tag, Row, Col, List, Dropdown, Menu, message   } from 'antd';
import { InfoCircleOutlined, MoreOutlined, CloseOutlined } from '@ant-design/icons'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { User } from "firebase/auth";
import { getFirestore, doc, updateDoc, Timestamp, getDoc  } from "firebase/firestore"; 
import './GeneratingGifStyle.css';
import { SharedState, UsersSharedState, TabProps, CustomAxiosError, MainCharacter, Location, SupportingCharacter, Character } from './types';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

const { TextArea } = Input;
const { Option } = Select;

const StyledList = styled(List<OutlineItem>)`
  width: 95%;
  
  .ant-list-item {
    cursor: pointer;
  }
  
  .ant-list-item:hover {
    background-color: #f0f0f0;
  }
  
  .ant-list-item-selected {
    background-color: #e6f7ff;
  }
`;

interface OutlineItem {
  id: string;
  outline_text: string;
  chapterNumber: number;
}

const OutlineTab: React.FC<TabProps> = ({ sharedState, setSharedState, isAuth, user, usersSharedState, setUsersSharedState }) => {
	
	
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmOutlineModalVisible, setIsConfirmOutlineModalVisible] = useState(false);
  const [shouldSkipOutlineConfirm, setShouldSkipOutlineConfirm] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState<number | null>(null);
  const [currentChapterTitle, setCurrentChapterTitle] = useState('');

  const [apiModel, setApiModel] = useState("Claude")
  const [costState, setCostState] = useState<Record<string, any>>({}); 
  const [bookNumChapters, setBookNumChapters] = useState(6)
  const [bookPOV, setBookPOV] = useState("First Person")

  const [outlineItems, setOutlineItems] = useState<OutlineItem[]>([]);
  const [selectedOutlineItem, setSelectedOutlineItem] = useState<OutlineItem | null>(null);
  const sortedOutlineItems = [...outlineItems].sort((a, b) => a.chapterNumber - b.chapterNumber);

  const [locations, setLocations] = useState<Location[]>([]);
  const [mainCharacters, setMainCharacters] = useState<MainCharacter[]>([]);
  const [supportingCharacters, setSupportingCharacters] = useState<SupportingCharacter[]>([]);
  const allCharacters = [...mainCharacters, ...supportingCharacters];
  const [bookTense, setBookTense] = useState("Past Tense")
  const [bookGenre, setBookGenre] = useState("")
  const [bookWritingStyle, setBookWritingStyle] = useState("")
  const [bookTropes, setBookTropes] = useState("")


  const db = getFirestore();
  
  const outlineTotalCost = Math.ceil(costState.genOutlineCost * bookNumChapters);
  
   ///Load Book data
  useEffect(() => {
    const fetchData = async () => {
      if (!sharedState.bookguid) return;  // Exit if no bookguid is available
	  console.log("Fetching book data (location)")
      setIsLoading(true);
      try {
        const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/api/books/${sharedState.bookguid}`);
        const data = response.data;

        let parsedLocations = [];
        try {
            parsedLocations = JSON.parse(data.locations || '[]');
            //console.log("Parsed locations:", parsedLocations);  // Log parsed data
            if (parsedLocations && parsedLocations.locations) {
                setLocations(parsedLocations.locations);  // Use the array inside the 'locations' property
            } else {
                setLocations([]);  // Default to an empty array if the structure isn't as expected
            }
        } catch (error) {
            console.error("Failed to parse locations data: ", error);
            setLocations([]);  // Default to an empty array in case of parsing error
        }

		//console.log("data.outline:"+data.outline)
		setApiModel(data.model);   	
		setBookNumChapters(data.numChapters);
		setBookPOV(data.pov);	
		//console.log("bookOutline:"+bookOutline)
		
        if (data.mainCharacters) {
          setMainCharacters(data.mainCharacters);  // Ensure this is an array
        }
        if (data.supportingCharacters) {
          setSupportingCharacters(data.supportingCharacters);  // Ensure this is an array
        }
		
        setBookTense(data.tense);
        setBookGenre(data.genre);
        setBookWritingStyle(data.writingStyle);
        setBookTropes(data.tropes);
		  
      } catch (error: any) {
        console.error("Failed to fetch book details: ", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sharedState.bookguid]);  

  useEffect(() => {
    const fetchGenerationCosts = async () => {
      setIsLoading(true);
      try {
        const generationCostsResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/get_generation_cost/');
        const costsData: Record<string, any> = generationCostsResponse.data.creditCosts;
        const generationTypes = [
          'BasicPlot', 'Tropes', 'WritingStyle', 'MainCharacters', 
          'SupportingCharacters', 'Locations', 'Outline', 
          'Title', 'Blurb', 'Novel', 'ChapterSummary','CharacterName','CharacterDetails',
          'LocationName','LocationDetails'
        ];

        let newState: Record<string, any> = {}; 
        for (let genType of generationTypes) {
          const key = `${genType}_${apiModel}`;
          newState[`gen${genType}Cost`] = costsData[key];
        }

        // Updating the new costState with the fetched costs
        setCostState(newState);
        
		console.log("Cost State genBasicPlotCost: "+costState.genBasicPlotCost);
		
      } catch (error) {
        console.error("Failed to fetch generation costs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (apiModel) {  // Ensuring model is available before fetching
      fetchGenerationCosts();
    }
  }, [sharedState.bookguid, apiModel]);  


  useEffect(() => {
    if (sharedState.bookguid) {
      // Fetch the outline data when the book is loaded
      fetchOutlineData();
    }
  }, [sharedState.bookguid]);  


  const fetchOutlineData = async () => {
    try {
      const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/api/books/${sharedState.bookguid}`);
      const data = response.data;
      if (data.outline) {
        setOutlineItems(data.outline);
      }
    } catch (error) {
      console.error("Failed to fetch outline data: ", error);
    }
  };

  const handleOutlineItemSelect = (item: OutlineItem) => {
    setSelectedOutlineItem(item);
  };

  const handleOutlineTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (selectedOutlineItem) {
      setSelectedOutlineItem({
        ...selectedOutlineItem,
        outline_text: e.target.value
      });
    }
  };

const handleSaveOutline = async () => {
  setIsLoading(true);
  if (selectedOutlineItem && sharedState.bookguid && usersSharedState.userID) {
    try {
      // Call the API to save the outline
      const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/api/save_outline/', {
        bookguid: sharedState.bookguid,
        userid: usersSharedState.userID,
        outline_id: selectedOutlineItem.id,
        outline_text: selectedOutlineItem.outline_text
      });

      // Check if the API call was successful
      if (response.status === 200) {
        // Update the local state
        setOutlineItems(prevItems => 
          prevItems.map(item => 
            item.id === selectedOutlineItem.id ? selectedOutlineItem : item
          )
        );

        message.success('Outline saved successfully');
      } else {
        throw new Error('Failed to save outline');
      }
    } catch (error) {
      console.error("Error saving outline: ", error);
      message.error('Failed to save outline');
    }
	finally {
	  setIsLoading(false);
	}
  }
};
 
const handleCopyEntireOutline = () => {
  const sortedOutlineItems = [...outlineItems].sort((a, b) => a.chapterNumber - b.chapterNumber);
  
  const outlineText = sortedOutlineItems
    .map(item => `${item.outline_text}`)
    .join('\n\n');
  
  navigator.clipboard.writeText(outlineText)
    .then(() => {
      message.success('Entire outline copied to clipboard');
    })
    .catch(err => {
      console.error('Failed to copy text: ', err);
      message.error('Failed to copy outline');
    });
};
 
  const handleInfoModal = (visible: boolean, source: string) => {
    if (source === 'Outline')
	{
	setInfoTitle("Outline Information");
    setInfoContent(`ScribeShadow can generate an outline for your story. 
	
	ScribeShadow will use the Genre, Tropes, Plot, Main Characters, Supporting Characters, and Locations provided so far when creating your outline. You can also write your own outline. 
	
	Any character names or locations included in the Basic Plot, Main Characters, Supporting Characters, or Locations could be used when ScribeShadow generates your outline, so make sure you are happy with them before continuing.
	
	The length of your outline will depend on the number of chapters you specified in the High Level tab.
	
	ScribeShadow will use the the Outline when generating your Novel.`);
    }	
    setIsInfoModalVisible(visible);
  };

  let infoModalContent = (
    <p>
      {infoContent.split('\n').map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );
    
  const handleErrorModal = (visible: boolean) => {
    setErrorModalVisible(visible);
  };
  
  // Confirmation handlers
  const handleConfirmOutlineModal = async (confirm: boolean) => {
    setIsConfirmOutlineModalVisible(false);
    if (confirm) {
      handleGenerateOutline();
    }
  };
  
  const handleGenerateOutlineClick = async () => {
      handleGenerateOutline();
  };
  
const handleGenerateOutline = async () => {
  setIsLoading(true);
  try {
	  
    const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/generate_outline/', {
      userID: usersSharedState.userID,
      bookguid: sharedState.bookguid
    });
		
	await fetchOutlineData();	
	message.success('Outline generated successfully');
	
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response && axiosErr.response.data && typeof (axiosErr.response.data as any).detail === 'string') {
      setErrorMessage((axiosErr.response.data as any).detail);
    } else {
      setErrorMessage(`An error occurred while generating the Outline.\n ${err}`);
    }
    handleErrorModal(true);
  } finally {
    setIsLoading(false);
  }
};


const handleRegenerateChapterOutline = async (suggestion?: string) => {
  setIsLoading(true);
  try {
    // Assuming that apimodel and other required parameters are available from sharedState
    const result = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/regenerate_chapter_outline/', {
      apimodel: apiModel,
      userID: usersSharedState.userID,
      chapterNumber: selectedChapter,
      bookguid: sharedState.bookguid
    });

    // Assuming that the API returns the updated outline in result.data.outline
    const updatedOutline = result.data.outline;

    // Update Firestore document
    const bookRef = doc(db, 'books', sharedState.bookguid);
    await updateDoc(bookRef, { outline: JSON.stringify(updatedOutline) });
    
	} catch (error) {
		if (axios.isAxiosError(error)) {
			setErrorMessage(`An error occurred while generating the Outline.\n ${error.response?.data.detail}`);
		} else {
			setErrorMessage(`An error occurred while generating the Outline.\n ${error}`);
		}
		handleErrorModal(true);
		setIsLoading(false);
	}
  
  setIsLoading(false);
};




return (
  <Spin spinning={isLoading} tip="Saving...">
    <div>
      {!sharedState.bookguid && isAuth && (
        <Tooltip title="Please start or open a book in the Book Management tab.">
          <p><b>Please start or open a book in the Book Management tab.<br/>Fields will become available once a book is in progress.</b></p>
        </Tooltip>
      )}
      {!isAuth && (
        <Tooltip title="Please click Get Started.">
          <p><b>Click Get Started to sign up and start generating books!</b></p>
        </Tooltip>
      )}
        <Row gutter={16}>
          <Col span={5.5}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={{marginRight: '5px', fontSize: '16px'}}><b>Outline</b></p>
                <Tooltip title="Click for more information">
                  <InfoCircleOutlined
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={() => handleInfoModal(true, 'Outline')}
                  />
                </Tooltip>
              </div>
              <div style={{ height: '745px', overflow: 'auto' }}>
<StyledList
  dataSource={sortedOutlineItems}
  renderItem={(item: OutlineItem) => (
    <List.Item
      key={item.id}
      style={{
        cursor: 'pointer',
        backgroundColor: selectedOutlineItem?.id === item.id ? '#e6f7ff' : undefined
      }}
      onClick={() => handleOutlineItemSelect(item)}
    >
      Chapter {item.chapterNumber}
    </List.Item>
  )}
/>
              </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>


      <Button 
        type="primary" 
        onClick={handleGenerateOutline}
        disabled={false}
		style={{ marginTop: '10px' }}

      >
        Generate Outline
      </Button>

<Button 
  onClick={handleCopyEntireOutline}
  disabled={outlineItems.length === 0}
  style={{ marginTop: '10px' }}
>
  Copy Entire Outline
</Button>
     			
            </div>
          </div>
          </Col>
          <Col span={18}>
            <div>
              <p style={{marginRight: '5px', fontSize: '16px'}}><b>Outline Details</b></p>
              {selectedOutlineItem ? (
                <>
                  <TextArea
                    value={selectedOutlineItem.outline_text}
                    onChange={handleOutlineTextChange}
                    autoSize={{ minRows: 10, maxRows: 34 }}
                  />
                  <Button onClick={handleSaveOutline} style={{ marginTop: '10px' }}>Save Outline</Button>
                </>
              ) : (
                <p style={{textAlign: "center"}}>Select a chapter to view and edit its outline</p>
              )}
            </div>
          </Col>
        </Row>
      
      {/* Modals */}
      <Modal 
        title={infoTitle}
        visible={isInfoModalVisible}
        onOk={() => handleInfoModal(false, "")}
        onCancel={() => handleInfoModal(false, "")}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="submit" type="primary" onClick={() => handleInfoModal(false, "")}>
              OK
            </Button>
          </div>
        }
      >
        {infoModalContent}
      </Modal>
      
      <Modal 
        title="Error"
        visible={isErrorModalVisible}
        onOk={() => setErrorModalVisible(false)}
        onCancel={() => setErrorModalVisible(false)}
        footer={
          <div style={{ textAlign: 'center' }}>
            <Button key="submit" type="primary" onClick={() => setErrorModalVisible(false)}>
              OK
            </Button>
          </div>
        }
      >
        <p>{errorMessage}</p>
      </Modal>
      
      <Modal
        title={null}
        visible={isLoading}
        footer={null}
        centered
        closable={false}
        maskClosable={false}
        style={{ 
          textAlign: 'center', 
          background: 'transparent', 
          boxShadow: 'none',
          width: 'auto'
        }}
      >
        <img src="generating.gif" alt="Generating..." className="responsive-gif" />
      </Modal>	  

      <Modal 
        title="Are you sure you want to regenerate your Outline?"
        visible={isConfirmOutlineModalVisible}
        onOk={() => handleConfirmOutlineModal(true)}
        onCancel={() => handleConfirmOutlineModal(false)}
      >
        <p>If you proceed, the existing Outline text will be deleted and replaced. <br/>
          
        Regenerating Outline will NOT change any other text fields already generated.<br/><br/>
        
        Do you want to continue?</p>
        <Checkbox onChange={(e) => setShouldSkipOutlineConfirm(e.target.checked)}>Don't show this again for this session</Checkbox>
      </Modal>
    </div>
  </Spin>
);
};

export default OutlineTab;
