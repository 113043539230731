// App.tsx

import { initializeApp } from 'firebase/app';
import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, User, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut as signOutAuth, onAuthStateChanged, browserLocalPersistence, setPersistence,sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { app } from "./firebaseConfig"; // Your firebase config file
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getFirestore, doc, setDoc, updateDoc, Timestamp, serverTimestamp, getDoc, collection, query, where, getDocs  } from "firebase/firestore"; 
import HeaderComponent from './HeaderComponent';
import SignInModalComponent from './SignInModalComponent';
import Create from './Create';
import Account from './Account';
import Pricing from './Pricing';
import Support from './Support';
import About from './About';
import BetaSignup from './BetaSignup';
import Marketing from './Marketing';
import ManageBooks from './ManageBooks'; 
import BookDetailPage from './BookDetailPage';
import AdminDashboard from './AdminDashboard';
import CreditHistory from './CreditHistory';
import PasswordResetComponent from './PasswordResetComponent';
import { isMobile } from 'react-device-detect';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {message} from 'antd';

import { MainCharacter, SupportingCharacter } from './Create/types';

const originalConsoleError = console.error;
console.error = (...args) => {
  if (typeof args[0] === 'string' && args[0].startsWith('ResizeObserver loop')) {
    return;
  }
  originalConsoleError(...args);
};


// Initialize Firebase
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);
const currentTime = Timestamp.now();

//stripe
const stripePromise = loadStripe('pk_test_51NRFOxEj6HHcTsCPZWI7KUxJcQq7TZgZt0Qw2szlLNaJCui7rGVu3DobCJplMSnNY0YgtjYKNopwVugT0hd9dZOW00DjQXeL7x'); 

interface FormValues {
  username: string;
  password: string;
  actionType: string;
}

interface SubscriptionData {
  maxCharacters?: number;
  maxLocations?: number;
  maxChapters?: number;
  gpt4Allowed?: boolean;
}

const App: React.FC = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string | null>(null);

  const [showVerificationWarning, setShowVerificationWarning] = useState(false);


  const handleResetModalCancel = () => {
    setIsResetModalVisible(false);
  };

  const db = getFirestore(app);

const initialSharedState = {
  bookguid: "",
  model: "gpt-3.5"
};

const [sharedState, setSharedState] = useState(initialSharedState);

const resetSharedState = () => {
  setSharedState(initialSharedState);
}

const resetUserSharedState = () => {
  setUsersSharedState(initialUserSharedState);
}

  const initialUserSharedState = {
    userID: '',
    accountType: 0,
    credits: 0,
	maxCharacters: 1,
    maxLocations: 1,
    maxChapters: 1,
	gpt4Allowed: false
  };

  const [usersSharedState, setUsersSharedState] = useState(initialUserSharedState);

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, async (user) => {
    if (user) {
      setIsAuth(true);
      setUser(user);

      // Check if the user's email is verified
      setShowVerificationWarning(!user.emailVerified);

      const docRef = doc(db, 'users', user.uid);
      let docSnap = await getDoc(docRef);

      let userData;

      if (!docSnap.exists()) {
        await setDoc(docRef, {
          accountType: 0,
          credits: 0,
          foreverCredits: 5000,
          emailAddress: user.email, // Set user's email
          createDate: serverTimestamp(),
          stripeProductID: "poeTrial"
        }, { merge: true }); 

        // Refresh the docSnap after setting the document
        docSnap = await getDoc(docRef);
        userData = docSnap.data();
      } else {
        userData = docSnap.data();
      }

      // Fetch subscription details from Firestore
      const subscriptionPlansRef = collection(db, 'subscriptionPlans');
      const q = query(subscriptionPlansRef, where("stripeProductID", "==", userData?.stripeProductID));
      const querySnapshot = await getDocs(q);
      let subscriptionData: SubscriptionData | undefined;
      querySnapshot.forEach((doc) => {
        subscriptionData = doc.data() as SubscriptionData;
      });
      
      setUsersSharedState({
        userID: user.uid,
        accountType: userData?.accountType ?? 0,
        credits: userData?.credits ?? 0,
        maxCharacters: subscriptionData?.maxCharacters ?? 1,
        maxLocations: subscriptionData?.maxLocations ?? 1,
        maxChapters: subscriptionData?.maxChapters ?? 1,
        gpt4Allowed: subscriptionData?.gpt4Allowed ?? false,
      });
    } else {
      setIsAuth(false);
      setUser(null);
    }
  });

  return () => unsubscribe();
}, [db]);



useEffect(() => {
  if (user) {
    setShowVerificationWarning(!user.emailVerified);
  }
}, [user]);

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  const auth = getAuth();
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const user = result.user;
      
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        setDoc(docRef, {
          accountType: 0,
          credits: 0,
          foreverCredits: 5000,
          emailAddress: user.email, // Set user's email
          createDate: serverTimestamp(),
	      stripeProductID: "poeTrial"
        }, { merge: true }); 
      }

      const userData = docSnap.data();

      // Fetch subscription details from Firestore
      const subscriptionPlansRef = collection(db, 'subscriptionPlans');
      const q = query(subscriptionPlansRef, where("stripeProductID", "==", userData?.stripeProductID));
      const querySnapshot = await getDocs(q);
      let subscriptionData: SubscriptionData | undefined;
      querySnapshot.forEach((doc) => {
        subscriptionData = doc.data();
      });

      setUsersSharedState({
        userID: user.uid,
        accountType: userData?.accountType ?? 0,
        credits: userData?.credits ?? 0,
        maxCharacters: subscriptionData?.maxCharacters ?? 1,
        maxLocations: subscriptionData?.maxLocations ?? 1,
        maxChapters: subscriptionData?.maxChapters ?? 1,
        gpt4Allowed: subscriptionData?.gpt4Allowed ?? false,
      });

      setIsLoginModalVisible(false);
    }).catch((error) => {
      console.error("Error during sign in with Google:", error.message);
      setIsLoginModalVisible(false);
    });
};

const [error, setError] = useState<string | null>(null);

const onLogin = async (values: { username: string, password: string }) => {
  setError(null);
  setLoading(true);
  setModalMessage(null);
  const auth = getAuth();
  const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  
  if (!emailFormat.test(values.username)) {
    setError("The email address is not in correct format.");
    setLoading(false);
    return;
  }

  signInWithEmailAndPassword(auth, values.username, values.password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);
	  
      if (!docSnap.exists()) {
        setDoc(docRef, {
          accountType: 0,
          foreverCredits: 5000,
          emailAddress: user.email, // Set user's email
          createDate: serverTimestamp(),
          stripeProductID: "poeTrial"
        }, { merge: true }); 
      }

      const userData = docSnap.data();

      // Fetch subscription details from Firestore
      const subscriptionPlansRef = collection(db, 'subscriptionPlans');
      const q = query(subscriptionPlansRef, where("stripeProductID", "==", userData?.stripeProductID));
      const querySnapshot = await getDocs(q);
      let subscriptionData: SubscriptionData | undefined;
      querySnapshot.forEach((doc) => {
        subscriptionData = doc.data();
      });

      setUsersSharedState({
        userID: user.uid,
        accountType: userData?.accountType ?? 0,
        credits: userData?.credits ?? 0,
        maxCharacters: subscriptionData?.maxCharacters ?? 1,
        maxLocations: subscriptionData?.maxLocations ?? 1,
        maxChapters: subscriptionData?.maxChapters ?? 1,
        gpt4Allowed: subscriptionData?.gpt4Allowed ?? false,
      });

      setIsLoginModalVisible(false);
	  setError(null);
	  setLoading(false);
    })
    .catch((error) => {
      setError(getErrorMessage(error.code));
      console.error("Error during sign in with Email/Password:", error.message);
      setLoading(false);
    });
};

const onRegister = async (values: { username: string, password: string }) => {
  setError(null);
  setLoading(true);
  setModalMessage(null);
  const auth = getAuth();
  const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  
  if (!emailFormat.test(values.username)) {
    setError("The email address is not in correct format.");
    setLoading(false);
    return;
  }

  createUserWithEmailAndPassword(auth, values.username, values.password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      
      try {
        await sendEmailVerification(userCredential.user);
        message.success('Verification email sent.');
      } catch (error) {
        //console.error("Error sending verification email:", error.message);
        setError("Failed to send verification email. Please try again later.");
      }
	  
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);
	  
      if (!docSnap.exists()) {
        setDoc(docRef, {
          accountType: 0,
          foreverCredits: 5000,
          emailAddress: user.email, // Set user's email
          createDate: serverTimestamp(),
          stripeProductID: "poeTrial"
        }, { merge: true }); 
      }

      const docSnapRefresh = await getDoc(docRef);
      const userData = docSnapRefresh.data();
      
      // Fetch subscription details from Firestore
      const subscriptionPlansRef = collection(db, 'subscriptionPlans');
      const q = query(subscriptionPlansRef, where("stripeProductID", "==", userData?.stripeProductID));
      const querySnapshot = await getDocs(q);
      let subscriptionData: SubscriptionData | undefined;
      querySnapshot.forEach((doc) => {
        subscriptionData = doc.data();
      });

      setUsersSharedState({
        userID: user.uid,
        accountType: userData?.accountType ?? 0,
        credits: userData?.credits ?? 0,
        maxCharacters: subscriptionData?.maxCharacters ?? 1,
        maxLocations: subscriptionData?.maxLocations ?? 1,
        maxChapters: subscriptionData?.maxChapters ?? 1,
        gpt4Allowed: subscriptionData?.gpt4Allowed ?? false,
      });

      setIsLoginModalVisible(false);
      setError(null);
      setLoading(false);
    })
    .catch((error) => {
      setError(getErrorMessage(error.code));
      console.error("Error during sign up with Email/Password:", error.message);
      setLoading(false);
    });
};

const getErrorMessage = (code: string) => {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'This email is already in use. Please use a different email.';
    case 'auth/invalid-email':
      return 'This email is invalid. Please enter a valid email address.';
    case 'auth/user-disabled':
      return 'This user has been disabled. Please contact support.';
    case 'auth/user-not-found':
      return 'User not found. Please check your email or sign up.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/weak-password':
      return 'Your password is too weak. Please try a stronger password.';
    default:
      return 'An error occurred. Please try again later.';
  }
};


const resendVerificationEmail = async () => {
  if (user) {
    try {
      await sendEmailVerification(user);
      message.success("Verification email sent.");
    } catch (error: any) {
      console.error("Error sending verification email:", error);
      message.error("Failed to send verification email. Please try again later.");
    }
  } else {
    setError("User is not authenticated.");
  }
};

const resetPassword = (email: string) => {
  const auth = getAuth();
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Email sent.
      console.log("Password reset email sent.");
    })
	.catch((error: any) => {
	  // An error happened.
	  console.error("Error during password reset:", error.message);
	});
};

const showLoginModal = () => {
  setIsLoginModalVisible(true);
};

const handleLoginModalCancel = () => {
  setIsLoginModalVisible(false);
};

const signOut = () => {
  signOutAuth(auth).then(() => {
    // Sign-out successful.
    setUser(null);
    setIsAuth(false);
    resetSharedState(); // Reset sharedState on sign out
	resetUserSharedState(); // Reset userSharedState on sign out
	setShowVerificationWarning(false);
  }).catch((error) => {
    // An error happened.
    console.error(error);
  });
};

const onFormSubmit = (values: FormValues) => {
  if (values.actionType === 'login') {
    onLogin(values);
  } else if (values.actionType === 'register') {
    onRegister(values);
  }
};

//		<Route 
//		path="/proofread" 
//		element={
//			<Proofread 
//			isAuth={isAuth} 
//			usersSharedState={usersSharedState} 
//			setUsersSharedState={setUsersSharedState} // pass it here
//			showLoginModal={showLoginModal} 
//			/>
//		} 
//		/>	
 

 return (
<Router>
  {showVerificationWarning && (
    <div style={{
        backgroundColor: 'yellow', 
        textAlign: 'center',
        padding: '10px 0',
        fontSize: '18px'
      }}>
      <span>Your email is not verified. Please check your inbox for the verification email.</span> 
      <button onClick={resendVerificationEmail} style={{
        marginLeft: '20px', 
        verticalAlign: 'middle'
      }}>
        Resend Verification Email
      </button>
    </div>
  )}
      <HeaderComponent showLoginModal={showLoginModal} signOut={signOut} user={user} usersSharedState={usersSharedState}  />
		<SignInModalComponent 
			isLoginModalVisible={isLoginModalVisible} 
			handleLoginCancel={handleLoginModalCancel} 
			onFormSubmit={onFormSubmit}
			signInWithGoogle={signInWithGoogle}
			resetPassword={resetPassword}
			error={error} // Pass the error state to the form component
			showResetModal={() => setIsResetModalVisible(true)} // Adding missing property
			setIsResetModalVisible={setIsResetModalVisible} // Adding missing property
			loading={loading} // Pass the loading state to the form component
			message={modalMessage} // Pass the message state to the form component
		/>
	  <PasswordResetComponent
	  isResetModalVisible={isResetModalVisible}
	  handleResetModalCancel={handleResetModalCancel}
	  resetPassword={resetPassword}
	  />
      <Routes>
        <Route path="/create" element={<Create isAuth={isAuth} user={user} usersSharedState={usersSharedState} setUsersSharedState={setUsersSharedState} sharedState={sharedState} setSharedState={setSharedState} />} />
		<Route 
		path="/account" 
		element={
			<Account 
			isAuth={isAuth} 
			usersSharedState={usersSharedState} 
			setUsersSharedState={setUsersSharedState} // pass it here
			showLoginModal={showLoginModal} 
			/>
		} 
		/>
		<Route 
		path="/pricing" 
		element={
			<Pricing 
			isAuth={isAuth} 
			usersSharedState={usersSharedState} 
			setUsersSharedState={setUsersSharedState}			
			showLoginModal={showLoginModal} 
			/>
		} 
		/>		
		<Route path="/support" element={<Support />} />
		<Route path="/about" element={<About />} />
			{/*
		<Route path="/marketing/:bookId" element={<Marketing />} />
			*/}
		<Route 
		path="/betaaccess" 
		element={
			<BetaSignup 
			isAuth={isAuth} 
			usersSharedState={usersSharedState} 
			setUsersSharedState={setUsersSharedState} // pass it here
			showLoginModal={showLoginModal} 
			/>
		} 
		/>
		<Route path="/manage-books" element={<ManageBooks user={user} />} />
		<Route path="/book-detail/:bookId" element={<BookDetailPage />} />
		<Route 
		path="/admindashboard" 
		element={
			<AdminDashboard  
			isAuth={isAuth} 
			usersSharedState={usersSharedState} 
			setUsersSharedState={setUsersSharedState} // pass it here
			showLoginModal={showLoginModal} 
			/>
		} 
		/>

        <Route 
          path="/credit-history" 
          element={
            <CreditHistory 
              isAuth={isAuth} 
              usersSharedState={usersSharedState} 
              setUsersSharedState={setUsersSharedState}
              showLoginModal={showLoginModal} 
            />
          } 
        />
		
        <Route path="/" 		element={
			<Account 
			isAuth={isAuth} 
			usersSharedState={usersSharedState} 
			setUsersSharedState={setUsersSharedState} // pass it here
			showLoginModal={showLoginModal} 
			/>
		} />
      </Routes>
    </Router>
  );
};

export default App;