//BetaSignup.tsx
import React, { useState, useEffect  } from 'react';
import { Card, Switch, Typography, Button, Modal, Divider, Row, Col, Descriptions, Space, Checkbox, message, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CheckCircleOutlined } from '@ant-design/icons';
import { CheckoutForm } from './CheckoutForm'; 
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UsersSharedState } from './Create/types';
import { CSSProperties } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const { Title, Text, Paragraph  } = Typography;


interface AccountProps {
  isAuth: boolean;
  usersSharedState: any;
  setUsersSharedState: any;
  showLoginModal: () => void;
}

interface PlanDetails {
  planName?: string;
  monthlyCredits?: number;
  tier?: number;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const BetaSignup: React.FC<AccountProps> = ({ isAuth, usersSharedState, setUsersSharedState, showLoginModal  }) => {
  
  const [isYearly, setIsYearly] = useState(false);
  const [planDetails, setPlanDetails] = useState<PlanDetails>({});
  const [isCheckoutModalVisible, setisCheckoutModalVisible] = useState(false);
  const [isLoadingPlanDetails, setIsLoadingPlanDetails] = useState(false);
  const [foreverCredits, setForeverCredits] = useState(0); 
  const pageDisabled = false;
  const disabledMessage = "After a lot of feedback, we're making improvements. Check back soon! -Team ScribeShadow";
  const [agreeToBeta, setAgreeToBeta] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isBetaUser, setIsBetaUser] = useState(false);
  const [isLoadingBetaStatus, setIsLoadingBetaStatus] = useState(true); 

  
useEffect(() => {
  const fetchDetails = async () => {
    if (isAuth && usersSharedState.userID) {
      setIsLoadingPlanDetails(true);
      try {
        const creditResponse = await axios.get('https://askpoeai-nswdzfky7q-uc.a.run.app/check_credit_balance/', {
          params: {
            userID: usersSharedState.userID,
          },
        });

        const planID = creditResponse.data.planID;
        const planResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/get_plan_details/${planID}`);
        const subscriptionResponse = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/subscription-details/${usersSharedState.userID}`);
        
        setUsersSharedState({
          ...usersSharedState,
          credits: creditResponse.data.credit_balance,
          maxCharacters: subscriptionResponse.data.maxCharacters ?? 1,
          maxLocations: subscriptionResponse.data.maxLocations ?? 1,
          maxChapters: subscriptionResponse.data.maxChapters ?? 1,
          gpt4Allowed: subscriptionResponse.data.gpt4Allowed ?? false,
        });

        setForeverCredits(creditResponse.data.forever_credits);
		setPlanDetails({
          planName: planResponse.data.planName,
          monthlyCredits: planResponse.data.monthlyCredits,
          tier: planResponse.data.tier,
        });

      } catch (error) {
        console.error('Failed to fetch details:', error);
      } finally {
        setIsLoadingPlanDetails(false);
      }
    }
  };
  fetchDetails();
}, [isAuth, usersSharedState.userID, planDetails.tier]);

  useEffect(() => {
    const checkBetaStatus = async () => {
      if (isAuth && usersSharedState.userID) {
        try {
          const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/check-beta-status/${usersSharedState.userID}`);
          setIsBetaUser(response.data.isBetaUser);
        } catch (error) {
          console.error('Error checking beta status:', error);
        } finally {
          setIsLoadingBetaStatus(false); // Set loading to false after fetching the status
        }
      }
    };

    checkBetaStatus();
  }, [isAuth, usersSharedState.userID]);


  const handleOptInOutBeta = async () => {
    if (!isAuth) {
      showLoginModal();
      return;
    }
    if (!isBetaUser && !agreeToBeta) {
      message.error('You must agree to the terms to join the beta program.');
      return;
    }

    try {
      await axios.post(`https://askpoeai-nswdzfky7q-uc.a.run.app/opt-in-out-beta/${usersSharedState.userID}`, { betaAccess: !isBetaUser });
      setIsBetaUser(!isBetaUser);
      message.success(`Successfully ${isBetaUser ? 'left' : 'joined'} the beta program!`);
    } catch (error) {
      console.error('Failed to update beta program status:', error);
      message.error('Error updating beta program status. Please try again later.');
    }
  };

  
  useEffect(() => {
    setSubmitDisabled(!agreeToBeta);
  }, [agreeToBeta]);  

const handleCheckboxChange = (e: CheckboxChangeEvent) => {
  setAgreeToBeta(e.target.checked);
};

  if (!isAuth) {
    return (
      <div style={{ backgroundColor: '#f0f2f5', padding: '2em', textAlign: 'center' }}>
        <Card style={{ backgroundColor: '#ffffff', padding: '2em', borderRadius: '5px', maxWidth: '700px', margin: '0 auto' }}>
          <Title level={2}>Join Our Beta Program</Title>
          <Paragraph>Please log in to access the beta program signup.</Paragraph>
          <Button type="primary" onClick={showLoginModal}>
            Log In
          </Button>
        </Card>
      </div>
    );
  }
  
    return (
    <div style={{ backgroundColor: '#f0f2f5', padding: '2em' }}>
      <Card style={{ backgroundColor: '#ffffff', padding: '2em', borderRadius: '5px', maxWidth: '1000px', margin: '0 auto' }}>
        <Title level={2} style={{ textAlign: 'center' }}>Beta Program</Title>

        {/* Display loading indicator while checking beta status */}
        {isLoadingBetaStatus ? (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <Spin size="large" />
            <Paragraph>Loading beta status...</Paragraph>
          </div>
        ) : (
          <>
		<Paragraph style={{ textAlign: 'center' }}>
		{isBetaUser ? (
			'You are currently participating in our Beta Program. Thank you for your contributions!'
		) : (
			<>
			Join our Beta Program and be the first to experience our latest features!
			<br />
			As a thank you for participating, you will receive a one-time grant of 5000 forever credits upon signup.
			<br />
			Please note, beta features are in development and might be refined based on user feedback.
			</>
		)}
		</Paragraph>
        {!isBetaUser && (
          <div style={{ textAlign: 'center' }}>
            <Checkbox onChange={handleCheckboxChange} checked={agreeToBeta} style={{ marginBottom: '1em' }}>
              I understand the nature of beta features.
            </Checkbox>
          </div>
        )}
        <div style={{ textAlign: 'center', marginBottom: '1em' }}>
          <Button type="primary" onClick={handleOptInOutBeta}>
            {isBetaUser ? 'Leave Beta Program' : 'Join Beta Program'}
          </Button>
        </div>
          </>
        )}
        <div style={{ textAlign: 'center' }}>
          <a href="mailto:contact@scribeshadow.com">
            <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
          <a href="https://discord.com/invite/k7vnT38vPq" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
          <a href="https://www.facebook.com/groups/216351367578868/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} style={{ fontSize: '24px', marginRight: '10px', color: '#1890ff' }} />
          </a>
        </div>		
        <Divider />

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} style={{ textAlign: 'center' }}>
            <Title level={4}>Features Currently in Beta</Title>
            
              <Paragraph style={{ textAlign: 'left' }}>
                <strong>Additional Translation Languages:</strong><br /> Translation support for Japanese, Russian, Thai, as well as Traditional and Simplified Chinese.
              </Paragraph>
              {/*
			  <Paragraph style={{ textAlign: 'left' }}>
                <strong>Novel Creation:</strong><br /> ScribeShadow can assist you in writing your novel! From tropes, to characters, to outlines, to entire chapters. Wherever your need help, ScribeShadow can assist.<br /><i>- During BETA testing, credit costs for novel creation are discounted and are subject change. -</i>
              </Paragraph>			  
			  */}

          </Col>
          <Col xs={24} sm={24} md={12} style={{ textAlign: 'center' }}>
            <Title level={4}>Beta Program FAQ</Title>
            <Paragraph style={{ textAlign: 'left' }}>
              <strong>What can I expect in the beta program?</strong><br />
              Beta participants get early access to new features. These features are still in testing and are subject to change based on testing and feedback.
            </Paragraph>
            <Paragraph style={{ textAlign: 'left' }}>
              <strong>Are beta features stable?</strong><br />
              Beta features are functional but may have more bugs than final versions. We welcome feedback to improve them.
            </Paragraph>
            <Paragraph style={{ textAlign: 'left' }}>
              <strong>How can I provide feedback?</strong><br />
              You can send feedback via email, our Facebook group, or our Discord community. We value your input!
            </Paragraph>
          </Col>
        </Row>
      </Card>
    </div>
  );
};


export default BetaSignup;
