//TranslationModal.tsx

import React from 'react';
import { Modal, Button, Select, Checkbox, Tooltip, Badge, Tag } from 'antd';
import { QuestionCircleOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

interface TranslationModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onTranslate: () => void;
  selectedChapter: any;
  translateVersion: string;
  setTranslateVersion: (version: string) => void;
  bookLanguage: string;
  targetLanguage: string;
  setTargetLanguage: React.Dispatch<React.SetStateAction<string>>;
  handleLanguageChange: (value: string, type: 'source' | 'target') => void;
  languages: string[];
  betaLanguages: string[];
  betaLanguageWarning: boolean;
  setBetaLanguageWarning: (warning: boolean) => void;
  quotationStyle: string;
  setQuotationStyle: (style: string) => void;
  selectedAIModel: string;
  setSelectedAIModel: (model: string) => void;
  isBetaUser: boolean;
  creditBalance: any;
  getCreditCost: () => number;
  isChapterTranslated: boolean;
  isPreviousChapterTranslated: boolean;
  hasUntranslatedChapters: boolean;
  userHasEnoughCredits: boolean;
  hasUnproofreadChapters: boolean;
  proofreadOptionProps: {
    value: string;
    disabled: boolean;
    text: string;
  };
}

const TranslationModal: React.FC<TranslationModalProps> = ({
  isVisible,
  onCancel,
  onTranslate,
  selectedChapter,
  translateVersion,
  setTranslateVersion,
  bookLanguage,
  targetLanguage,
  setTargetLanguage,
  handleLanguageChange,
  languages,
  betaLanguages,
  betaLanguageWarning,
  setBetaLanguageWarning,
  quotationStyle,
  setQuotationStyle,
  selectedAIModel,
  setSelectedAIModel,
  isBetaUser,
  creditBalance,
  getCreditCost,
  isChapterTranslated,
  isPreviousChapterTranslated,
  hasUntranslatedChapters,
  userHasEnoughCredits,
  hasUnproofreadChapters,
  proofreadOptionProps,
}) => {
		
  return (
    <Modal
      title={selectedChapter ? "Translate Chapter" : "Translate Book"}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>Cancel</Button>,
        <Button 
          key="translate" 
          type="primary" 
          onClick={onTranslate} 
          disabled={
            !targetLanguage || !translateVersion || bookLanguage === targetLanguage ||
            (!hasUntranslatedChapters && !selectedChapter) ||
            !userHasEnoughCredits ||
            (translateVersion === 'Proofread' && hasUnproofreadChapters && !selectedChapter)
          }
        >
          Translate
        </Button>
      ]}
    >
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Version to Translate:</label>
        <Select
          value={translateVersion}
          onChange={value => setTranslateVersion(value)}
          style={{ width: '100%' }}
        >
          <Select.Option value="Original">Original</Select.Option>
          <Select.Option value={proofreadOptionProps.value} disabled={proofreadOptionProps.disabled}>
            {proofreadOptionProps.text}
          </Select.Option>
        </Select>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Origin Language:</label>
        <div>{bookLanguage}</div>
      </div>
      <div>
        <label style={{ marginRight: '10px' }}>Target Language:</label>
        <Select
          value={targetLanguage}
          onChange={(value) => {
            setTargetLanguage(value); // Update the local state
            handleLanguageChange(value, 'target');
            setBetaLanguageWarning(betaLanguages.includes(value));
            if (value === 'German') {
              setQuotationStyle('german quotation marks');
            }  
          }}
          style={{ width: '100%', marginBottom: '10px' }}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{maxHeight: '5000px' }}
        >
          {languages.filter(lang => lang !== bookLanguage).map(lang => (
            <Select.Option key={lang} value={lang}>
              {lang} {betaLanguages.includes(lang) ? "(BETA)" : ""}
            </Select.Option>
          ))}
        </Select>      
      </div>
      {betaLanguageWarning && (
        <div style={{ marginBottom: '10px', color: '#CC5500' }}>
          <strong>Note:</strong> The translation for {targetLanguage} is currently in beta. While we strive for accuracy, there may be variations in quality. We welcome your feedback to improve this service.
        </div>
      )}
      
      {targetLanguage === 'German' && (
        <div style={{ marginBottom: '10px' }}>
          <label style={{ marginRight: '10px' }}>Quotation Style:</label>
          <Select
            value={quotationStyle}
            onChange={value => setQuotationStyle(value)}
            style={{ width: '100%' }}
          >
            <Select.Option value="german quotation marks">German Quotation Marks („Dialog Text")</Select.Option>
            <Select.Option value="german guillemets">Guillemets (»Dialog Text«)</Select.Option>
          </Select>
        </div>
      )}  
      
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>AI Model:</label>
        <Select
          value={selectedAIModel}
          onChange={(value) => setSelectedAIModel(value)}
          style={{ width: '100%' }}
        >
          <Select.Option value="Chat-GPT">Chat-GPT</Select.Option>
<Select.Option value="Claude">
Claude 3.5 <Tag color="geekblue" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Recommended <CheckCircleOutlined /></Tag>
</Select.Option>
		  <Select.Option value="Claude3">Claude 3</Select.Option>
<Select.Option value="DeepSeek">
  DeepSeek <Tag color="green" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>50% Off</Tag>
  <Tag color="red" style={{ marginLeft: '8px', position: 'relative', top: '-0.5px' }}>Allows NSFW</Tag>
</Select.Option>
		  {/*<Select.Option value="Wizard" disabled={!isBetaUser}>
		  {isBetaUser ? "Wizard" : "Wizard (Requires Beta access)"}
		  </Select.Option>			 */} 
        </Select>
      </div>  

      <div>
        <label>Credit Balance: </label>
        <span>{creditBalance ? creditBalance.credit_balance : 'Loading...'}</span>
      </div>
      <div>
        <label>Translation Credit Cost: </label>
        <span>{getCreditCost()}</span>
        <Tooltip title="This value may differ from the word count of your document due to the inclusion of hidden tags and metadata necessary to maintain formatting integrity.">
          <QuestionCircleOutlined style={{ color: 'blue', marginLeft: 8 }} />
        </Tooltip>	
      </div>
      {isChapterTranslated && <div style={{ color: '#CC5500' }}><ExclamationCircleOutlined style={{ color: '#CC5500', marginRight: 4 }} /> The selected chapter is already translated and will be overwritten.</div>}
	  {selectedChapter && !isPreviousChapterTranslated && <div style={{ color: '#CC5500' }}><ExclamationCircleOutlined style={{ color: '#CC5500', marginRight: 4 }} /> The previous chapter has not been translated. 
        <Tooltip title="ScribeShadow can analyze character relationship changes from chapter to chapter, allowing better tranlation of formal and informal pronouns. As such, we recommend translating chapters in order so ScribeShadow can better interperate character relationship dynamics. You may disregard this warning if the previous chapter was a title page, table of contents, or other non-story material.">
          <QuestionCircleOutlined style={{ color: 'blue', marginLeft: 8 }} />
        </Tooltip></div>	}
      {!hasUntranslatedChapters && !selectedChapter && <div style={{ color: 'red' }}><ExclamationCircleOutlined style={{ color: 'red', marginRight: 4 }} />All chapters have been translated for the selected target language.</div>}
      {hasUntranslatedChapters && !selectedChapter && <div>ScribeShadow will only translate chapters that have not yet been translated.</div>}
      {!userHasEnoughCredits && <div style={{ color: 'red' }}><ExclamationCircleOutlined style={{ color: 'red', marginRight: 4 }} />You do not have enough credits for this translation.</div>}
      {hasUnproofreadChapters && !selectedChapter && translateVersion == 'Proofread' && <div style={{ color: 'red' }}><ExclamationCircleOutlined style={{ color: 'red', marginRight: 4 }} />Not all chapters are proofread.</div>}
    </Modal>
  );
};

export default TranslationModal;